import GlifCopilot from '../../../../assets/webgl-assets/mascot/copilot/copilot.glb'
import MatcapMascot from '../../../../assets/webgl-assets/matcaps/mascot.jpg'
import TextureStar from '../../../../assets/webgl-assets/textures/star.jpg'
import TextureCopilotEyesSSS from '../../../../assets/webgl-assets/mascot/copilot/textures/eyes_sss.jpg'
import TextureCopilotFaceSSS from '../../../../assets/webgl-assets/mascot/copilot/textures/face_sss.jpg'
import TextureCopilotGlassSSS from '../../../../assets/webgl-assets/mascot/copilot/textures/glass_sss.jpg'
import TextureCopilotGoggleSSS from '../../../../assets/webgl-assets/mascot/copilot/textures/goggle_sss.jpg'
import TextureCopilotHeadSSS from '../../../../assets/webgl-assets/mascot/copilot/textures/head_sss.jpg'

import {Color, Vector2} from 'three'
import type {Gltf, Image, TextureData} from './assets-type'

export const copilotAssets = {
  gltfs: {
    copilot: {
      src: GlifCopilot,
      scene: null,
    },
  } as {[key: string]: Gltf},
  images: {
    // matcap
    matcap_mascot: {
      src: MatcapMascot,
      texture: null,
      flipY: false,
    },
    star: {
      src: TextureStar,
      texture: null,
      flipY: false,
    },
    // copilot
    copilot_eyes_ao: {
      src: TextureCopilotEyesSSS,
      texture: null,
      flipY: false,
    },
    copilot_face_ao: {
      src: TextureCopilotFaceSSS,
      texture: null,
      flipY: false,
    },
    copilot_glass_ao: {
      src: TextureCopilotGlassSSS,
      texture: null,
      flipY: false,
    },
    copilot_goggle_ao: {
      src: TextureCopilotGoggleSSS,
      texture: null,
      flipY: false,
    },
    copilot_head_ao: {
      src: TextureCopilotHeadSSS,
      texture: null,
      flipY: false,
    },
  } as {[key: string]: Image},
}

const bodyColor = new Color(0x9e55f8)

export const copilotTextureData: {[key: string]: TextureData} = {
  eyes: {
    ao: 'copilot_eyes_ao',
    color: null,
    colorVec: new Color(0x3963ef),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  face: {
    ao: 'copilot_face_ao',
    color: null,
    colorVec: new Color(0x05014d),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  glass: {
    ao: 'copilot_glass_ao',
    color: null,
    colorVec: new Color(0x6325b0),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  goggle: {
    ao: 'copilot_goggle_ao',
    color: null,
    colorVec: new Color(0x995be3),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  head: {
    ao: 'copilot_head_ao',
    color: null,
    colorVec: bodyColor,
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
}
