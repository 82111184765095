import GlbDuck from '../../../../assets/webgl-assets/mascot/duck/duck.glb'
import MatcapMascot from '../../../../assets/webgl-assets/matcaps/mascot.jpg'
import TextureStar from '../../../../assets/webgl-assets/textures/star.jpg'
import TextureDuckBodySSS from '../../../../assets/webgl-assets/mascot/duck/textures/body_sss.jpg'
import TextureDuckBeakSSS from '../../../../assets/webgl-assets/mascot/duck/textures/beak_sss.jpg'
import TextureDuckCatEyesSSS from '../../../../assets/webgl-assets/mascot/duck/textures/eyes_sss.jpg'
import TextureDuckEyeballSSS from '../../../../assets/webgl-assets/mascot/duck/textures/eyeballs_sss.jpg'

import {Color, Vector2} from 'three'
import type {Gltf, Image, TextureData} from './assets-type'

export const duckAssets = {
  gltfs: {
    duck: {
      src: GlbDuck,
      scene: null,
    },
  } as {[key: string]: Gltf},
  images: {
    // matcap
    matcap_mascot: {
      src: MatcapMascot,
      texture: null,
      flipY: false,
    },
    star: {
      src: TextureStar,
      texture: null,
      flipY: false,
    },
    // duck
    duck_body_ao: {
      src: TextureDuckBodySSS,
      texture: null,
      flipY: false,
    },
    duck_beak_ao: {
      src: TextureDuckBeakSSS,
      texture: null,
      flipY: false,
    },
    duck_eyes_ao: {
      src: TextureDuckCatEyesSSS,
      texture: null,
      flipY: false,
    },
    duck_eyeballs_ao: {
      src: TextureDuckEyeballSSS,
      texture: null,
      flipY: false,
    },
  } as {[key: string]: Image},
}

export const duckTextureData: {[key: string]: TextureData} = {
  body: {
    ao: 'duck_body_ao',
    color: null,
    colorVec: new Color(0xf5ae33),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  beak: {
    ao: 'duck_beak_ao',
    color: null,
    colorVec: new Color(0xf5ae33),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  eyes: {
    ao: 'duck_eyes_ao',
    color: null,
    colorVec: new Color(0x000000),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  eyeballs: {
    ao: 'duck_eyeballs_ao',
    color: null,
    colorVec: new Color(0x000000),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
}
