export default `
uniform vec3 uColor1;
uniform vec3 uColor2;
uniform float uTime;

uniform vec2 uResolution;
uniform vec4 uProgress;
uniform vec3 uScrollProgress;

varying vec2 vUv;

const float PI = 3.14159265359;

float pointToLineDistance(float a, float b, float x1, float y1) {
    return abs(a * x1 - y1 + b) / sqrt(a * a + 1.0);
}

float fbm(float x, float y, float t) {
  float amplitude = 1.;
  float frequency = 1.;
  y = sin(x * frequency);
  y += sin(x*frequency*2.1 + t) * 4.5;
  y += sin(x*frequency*1.72 + t * 1.121) * 4.0;
  y += sin(x*frequency*2.221 + t * 0.437) * 5.0;
  y += sin(x*frequency*3.1122+ t * 4.269) * 2.5;
  y *= amplitude* 0.06;
  return y;
}

void main(){
  vec2 st = gl_FragCoord.xy / uResolution;
  st -= 0.5;
  st *= uResolution / uResolution.x;//min(uResolution.x, uResolution.y);
  st *= 1.0 + uScrollProgress.x * 0.2;
  st.y += 0.5;
  #if IS_ROUNDED == 1
  st.y -= 0.05;
  #endif

  float centerRadiantRadius = length((st + vec2(0.0, 0.0)));
  float bottomLightRadius = length(st + vec2(0.0, 4.0));

  float angle = atan(st.y, st.x);
  float radius = length(st);
  radius *= mix(
    mix(0.9, 1.05, fbm(angle * 4.0, angle * 2.0, uProgress.z * 1.0) * 0.5 + 0.5),
    1.1,
    uProgress.z
  );

  #if IS_ROUNDED == 1
    radius *= 1.2;
  #endif


  float maxRadius = mix(0.01, 0.86, uProgress.y);//mix(1.0, 1.4, fbm(angle * 2.0, angle * 2.0,  uProgress.z * 4.0) * 0.5 + 0.5) - (1.0 - uProgress.y);


  float radiantAngle = 0.26;
  float radiantOffset = 0.05;

  float a = 1.0;
  float b = 0.2;
  float blurRange = 0.2;

  #if IS_ROUNDED == 1
  blurRange = 0.25;
  #endif

  float light = 0.0;

  float d_right = pointToLineDistance(a, b, st.x, st.y);
  float light_right = smoothstep(blurRange, 0.0, d_right);
  light_right = mix(1.0, light_right, step(st.y, st.x * a + b));
  float d_left = pointToLineDistance(-a, b, st.x, st.y);
  float light_left = smoothstep(blurRange, 0.0, d_left);
  light_left = mix(1.0, light_left, step(st.y, -st.x * a + b));

  light = light_right * light_left;
  light = mix(light, 0.0, smoothstep(mix(0.0, 0.3, uProgress.y), maxRadius, radius));

  float colorFactor = light * light;
  #if IS_ROUNDED == 1
    colorFactor = light * light * 0.8;
  #else
    colorFactor = light * light * 1.3;
  #endif
  vec3 color = mix(uColor1, uColor2, colorFactor);
  color *= mix(1.7, 1.0, smoothstep(0.1, 0.5, radius));
  color += mix(0.2, 0.0, smoothstep(0.1, 0.5, radius));

  vec3 hsv = rgb2hsv(color);
  hsv.g += smoothstep(0.7, 0.1, radius) * 0.2;
  color = hsv2rgb(hsv);

  float alpha = light * uProgress.x;
  alpha = mix(alpha, alpha * 0.8, uScrollProgress.x);

  gl_FragColor = vec4(color, alpha);
}
`
