import GlbShield from '../../../../assets/webgl-assets/mascot/shield/shield.glb'
import MatcapMascot from '../../../../assets/webgl-assets/matcaps/mascot.jpg'
import TextureStar from '../../../../assets/webgl-assets/textures/star.jpg'
import TextureShieldSSS from '../../../../assets/webgl-assets/mascot/shield/textures/shield_sss.jpg'
import TextureShieldDiffuse from '../../../../assets/webgl-assets/mascot/shield/textures/shield_diffuse.jpg'
import TextureShieldBlur from '../../../../assets/webgl-assets/mascot/shield/textures/shield_blur.jpg'

import type {Gltf, Image} from './assets-type'

export const shieldAssets = {
  gltfs: {
    shield: {
      src: GlbShield,
      scene: null,
    },
  } as {[key: string]: Gltf},
  images: {
    matcap_mascot: {
      src: MatcapMascot,
      texture: null,
      flipY: false,
    },
    star: {
      src: TextureStar,
      texture: null,
      flipY: false,
    },
    shield_sss: {
      src: TextureShieldSSS,
      texture: null,
      flipY: false,
    },
    shield_diffuse: {
      src: TextureShieldDiffuse,
      texture: null,
      flipY: false,
    },
    shield_blur: {
      src: TextureShieldBlur,
      texture: null,
      flipY: true,
    },
  } as {[key: string]: Image},
}
