import type {RefObject} from 'react'
import type React from 'react'
import {useEffect, useRef} from 'react'
import Artwork from './webgl/artwork'

import type {MascotType} from './mascot-type'

export interface SectionIntroWebGLProps {
  mascotName: MascotType['name']
  mascotRef: RefObject<HTMLDivElement>
  containerRef: RefObject<HTMLDivElement>
  startCopyAnimation: () => void
  isMascotOnly?: boolean
}

const SectionIntroWebGL: React.FC<SectionIntroWebGLProps> = ({
  mascotName,
  mascotRef,
  containerRef,
  isMascotOnly,
  startCopyAnimation,
}) => {
  const webglRef = useRef<HTMLCanvasElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const animationFrameId = useRef(0)

  useEffect(() => {
    let artwork: Artwork | null = null
    let isInViewport = false

    let isReducedMotion = false
    const handleReduceMotionChange = (event: MediaQueryListEvent) => {
      isReducedMotion = event.matches

      if (isReducedMotion) {
        if (artwork) {
          artwork.update({isReducedMotion: true})
        }
      } else {
        if (isInViewport) {
          tick()
        }
      }
    }

    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    mediaQuery.addEventListener('change', handleReduceMotionChange)
    isReducedMotion = mediaQuery.matches

    const resizeFunc = () => {
      if (mascotRef.current && containerRef.current && wrapperRef.current) {
        const mascotRectData = mascotRef.current.getBoundingClientRect()
        const containerRectData = containerRef.current.getBoundingClientRect()
        const section = containerRef.current.parentNode as HTMLElement | null
        let height = containerRectData.height
        if (section) {
          const sectionRectData = section.getBoundingClientRect()
          height = sectionRectData.height
        }

        let offset = mascotName === 'shield' ? 500 : 400

        if (isMascotOnly) {
          const webglTop = mascotRectData.top - containerRectData.top
          const webglWidth = mascotRectData.width
          const webglHeight = mascotRectData.height
          const margin = 10

          wrapperRef.current.style.height = `${webglHeight + margin * 2}px`
          wrapperRef.current.style.width = `${webglWidth + margin * 2}px`
          wrapperRef.current.style.top = `${webglTop - margin}px`
          wrapperRef.current.style.left = `${mascotRectData.left - containerRectData.left - margin}px`

          if (artwork) {
            artwork.resize(0)
          }
        } else {
          const webglTop = mascotRectData.top - containerRectData.top
          const webglWidth = containerRectData.width
          const maxHeight = height - webglTop
          offset = Math.min(offset, maxHeight - containerRectData.height + webglTop)

          const webglHeight = Math.min(maxHeight, containerRectData.height - webglTop + offset) + 10
          const webglBottom = -offset

          wrapperRef.current.style.height = `${webglHeight}px`
          wrapperRef.current.style.width = `${webglWidth}px`
          wrapperRef.current.style.bottom = `${webglBottom}px`

          if (artwork) {
            artwork.resize(mascotRectData.height)
          }
        }
      }
    }

    if (wrapperRef.current && webglRef.current && mascotRef.current) {
      artwork = new Artwork(
        wrapperRef.current,
        webglRef.current,
        mascotRef.current,
        mascotName,
        isMascotOnly,
        isReducedMotion,
      )
      artwork.setStartCopyAnimation(startCopyAnimation)
      artwork.load(() => {
        artwork?.init()
        resizeFunc()
        if (isReducedMotion && artwork) {
          artwork.update({isReducedMotion: true})
        }
      })
    }

    resizeFunc()

    const scrollFunc = () => {
      if (artwork && isInViewport && !isReducedMotion) {
        artwork.scroll()
      }
    }

    // eslint-disable-next-line github/prefer-observers
    window.addEventListener('resize', resizeFunc)

    // eslint-disable-next-line github/prefer-observers
    window.addEventListener('scroll', scrollFunc)

    const tick = () => {
      if (!isReducedMotion) {
        if (artwork) {
          artwork.update({isReducedMotion: false})
        }
        animationFrameId.current = requestAnimationFrame(tick)
      }
    }

    const observer = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (artwork) artwork.toggleVisibility(entry.isIntersecting)
          if (isReducedMotion) return
          if (entry.isIntersecting) {
            // Canvas is in the viewport, start rendering
            if (!animationFrameId.current) {
              tick()
            }
            isInViewport = true
          } else {
            // Canvas is out of the viewport, stop rendering
            if (animationFrameId.current) {
              cancelAnimationFrame(animationFrameId.current)
              animationFrameId.current = 0
            }

            isInViewport = false
          }
        }
      },
      {threshold: 0.1}, // Adjust the threshold as needed
    )

    if (webglRef.current) {
      observer.observe(webglRef.current)
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
      window.removeEventListener('resize', resizeFunc)
      window.removeEventListener('scroll', scrollFunc)
      mediaQuery.removeEventListener('change', handleReduceMotionChange)
      observer.disconnect()
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="lp-SectionIntroWebGL" ref={wrapperRef as RefObject<HTMLDivElement>}>
      <canvas className="lp-SectionIntroWebGL-canvas" ref={webglRef as RefObject<HTMLCanvasElement>} />
    </div>
  )
}

export default SectionIntroWebGL

try{ SectionIntroWebGL.displayName ||= 'SectionIntroWebGL' } catch {}