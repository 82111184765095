// import MainScene from './main-scene'
// import Background from './background'
import Assets from './assets'
import Common from './common'
import MascotObj from './mascot-obj'
import ShieldObj from './shield-obj'
import Background from './background'

import {catAssets, catTextureData} from './params/cat'
import {copilotAssets, copilotTextureData} from './params/copilot'
import {duckAssets, duckTextureData} from './params/duck'
import {shieldAssets} from './params/shield'
import type {MascotType} from '../mascot-type'
import Mouse from './mouse'

// import {MathUtils} from 'three'

export default class Artwork {
  private $wrapper: HTMLElement
  private background: Background
  private assets: Assets
  private common: Common = new Common()
  private name: MascotType['name']
  private mascot: MascotObj
  private shield: ShieldObj
  private mouse: Mouse = new Mouse(this.common)
  private isFirstShow = false
  private isMascotOnly?: boolean

  constructor(
    $wrapper: HTMLElement,
    $canvas: HTMLCanvasElement,
    $mascot: HTMLElement,
    name: MascotType['name'],
    isMascotOnly: boolean | undefined,
    isReducedMotion: boolean,
  ) {
    this.$wrapper = $wrapper
    this.name = name
    this.isMascotOnly = isMascotOnly
    this.common.isReducedMotion = isReducedMotion

    if (!this.isMascotOnly) {
      this.background = new Background(this.common, this.name === 'shield')
      this.common.scene.add(this.background.group)

      if (this.common.isReducedMotion) {
        this.isFirstShow = true
        this.background?.show()
      }
    }
    switch (this.name) {
      case 'mona':
        this.assets = new Assets(catAssets.gltfs, catAssets.images)
        this.mascot = new MascotObj('cat', this.common, catAssets.gltfs, catAssets.images, catTextureData)
        this.common.scene.add(this.mascot.group)
        break
      case 'copilot':
        this.assets = new Assets(copilotAssets.gltfs, copilotAssets.images)
        this.mascot = new MascotObj(
          'copilot',
          this.common,
          copilotAssets.gltfs,
          copilotAssets.images,
          copilotTextureData,
        )
        this.common.scene.add(this.mascot.group)
        break
      case 'ducky':
        this.assets = new Assets(duckAssets.gltfs, duckAssets.images)
        this.mascot = new MascotObj('duck', this.common, duckAssets.gltfs, duckAssets.images, duckTextureData)
        this.common.scene.add(this.mascot.group)
        break
      case 'shield':
        this.assets = new Assets(shieldAssets.gltfs, shieldAssets.images)
        this.shield = new ShieldObj(this.common, shieldAssets.gltfs, shieldAssets.images)
        this.common.scene.add(this.shield.group)
        this.background.group.position.x = 0.3
        break

      default:
        break
    }

    if (this.isMascotOnly) {
      this.mascot.group.scale.set(6.5, 6.5, 6.5)
    }
    this.common.init({
      $wrapper: this.$wrapper,
      $canvas,
      $mascot,
    })
    this.mouse.init()

    this.common.camera.position.set(0, 0.0, 2.2)
    this.common.camera.lookAt(this.common.scene.position)
  }

  toggleVisibility(isVisible: boolean): void {
    if (isVisible && !this.isFirstShow) {
      this.isFirstShow = true
      this.background?.show()
      this.mascot?.show()
      this.shield?.show()
      if (this.common.startCopyAnimation) {
        this.common.startCopyAnimation()
      }
    }
  }

  load(callback: () => void): void {
    if (!this.assets) return
    this.assets.load(() => {
      // console.log('done', this.name)
      callback()
    })
  }

  setStartCopyAnimation(startCopyAnimation: () => void): void {
    this.common.startCopyAnimation = startCopyAnimation
  }

  init(): void {
    if (this.assets.images.star && this.assets.images.star.texture) {
      this.background?.init(this.assets.images.star.texture)
    }
    this.mascot?.init()
    this.shield?.init()
  }

  resize(mascotHeight: number = 75): void {
    const scale = (this.common.cameraTop * 2) / this.common.screenSize.y
    const masctOffsetY = scale * ((this.common.screenSize.y - mascotHeight) * 0.5 - 10)
    if (this.mascot && !this.isMascotOnly) this.mascot.group.position.y = masctOffsetY
    if (this.shield) this.shield.group.position.y = masctOffsetY
    this.common.resize()
  }

  scroll(): void {
    this.common.scroll()
    // this.background?.scroll(scrollValue)
  }

  resetMouse(): void {
    this.mascot?.resetLookat()
  }

  update({isReducedMotion}: {isReducedMotion: boolean}): void {
    this.common.update()
    this.mouse.update()
    this.mascot?.update(this.mouse.pos.target, this.mouse.pos.current, isReducedMotion)
    this.background?.update()
    this.common.renderer?.setRenderTarget(null)
    this.common.renderer?.render(this.common.scene, this.common.camera)
  }
}
