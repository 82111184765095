import {ThemeProvider} from '@primer/react-brand'

import Intro from './Intro'
import Automation from './Automation'
import Security from './Security'
import Collaboration from './Collaboration'
import CustomerStories from './CustomerStories'
import Cta from './Cta'
import BackToTop from './components/BackToTop/BackToTop'

export function Home() {
  return (
    <ThemeProvider colorMode="dark" className="lp-Home">
      <Intro />
      <Automation />
      <Security />
      <Collaboration />
      <CustomerStories />
      <Cta />
      <BackToTop />
    </ThemeProvider>
  )
}

try{ Home.displayName ||= 'Home' } catch {}