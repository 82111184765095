import {useRef, useEffect} from 'react'
import Artwork from './intro-hero-webgl/artwork'
import Common from './intro-hero-webgl/common'
import Assets from './intro-hero-webgl/assets'

const COPY = {
  webgl: {
    alt: 'Mona the Octocat, Copilot, and Ducky float jubilantly upward from behind the GitHub product demo accompanied by a purple glow and a scattering of stars.',
  },
}

export function IntroHero({
  setCopyScrollOpacity,
  setCopyScrollScale,
}: {
  setCopyScrollOpacity: (o: number) => void
  setCopyScrollScale: (o: number) => void
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameId = useRef(0)

  useEffect(() => {
    let isClosed = false
    let isReducedMotion = false

    const handleReduceMotionChange = (event: MediaQueryListEvent) => {
      isReducedMotion = event.matches

      if (isReducedMotion) {
        setCopyScrollScale(1)
        setCopyScrollOpacity(1)
        if (artwork) artwork.renderOnce()
      } else {
        tick()
      }
    }

    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    mediaQuery.addEventListener('change', handleReduceMotionChange)
    isReducedMotion = mediaQuery.matches

    const common = new Common({isReduceMotion: isReducedMotion})
    const assets = new Assets()
    const artwork = new Artwork(wrapperRef.current!, canvasRef.current!, common, assets)
    const resizeFunc = () => {
      const width = wrapperRef.current?.clientWidth || 0
      const windowWidth = document.body.clientWidth
      if (wrapperRef.current) {
        wrapperRef.current.style.left = `${Math.round(windowWidth * 0.5 - width * 0.5)}px`
      }
      artwork.resize()
      if (isReducedMotion) {
        artwork.renderOnce()
      }
    }
    resizeFunc()

    // eslint-disable-next-line github/prefer-observers
    window.addEventListener('resize', resizeFunc)
    const scrollFunc = () => {
      artwork.scroll()
    }

    // eslint-disable-next-line github/prefer-observers
    window.addEventListener('scroll', scrollFunc)

    assets.load(() => {
      if (!isClosed) {
        artwork.init()
        if (isReducedMotion) {
          artwork.renderOnce()
        }
      }
    })

    const tick = () => {
      if (!isReducedMotion) {
        artwork.update()

        if (common.isMobile) {
          setCopyScrollScale(1)
          setCopyScrollOpacity(1)
        } else {
          const scale = 1.0 - artwork.copyProgress.current * 0.1
          setCopyScrollScale(scale)

          const opacity = 1.0 - artwork.copyProgress.current
          setCopyScrollOpacity(opacity)
        }

        animationFrameId.current = requestAnimationFrame(tick)
      }
    }

    if (!isReducedMotion) {
      tick()
    }

    const mouseEnterFunc = () => {
      if (!isReducedMotion && artwork) {
        artwork.startCtaAnim()
      }
    }

    const mouseLeaveFunc = () => {
      if (!isReducedMotion && artwork) {
        artwork.stopCtaAnim()
      }
    }

    const primaryActionRef = document.querySelector('.js-hero-primary-action')
    const secondaryActionRef = document.querySelector('.js-hero-secondary-action')

    if (primaryActionRef) {
      primaryActionRef.addEventListener('mouseenter', mouseEnterFunc)
      primaryActionRef.addEventListener('mouseleave', mouseLeaveFunc)
    }

    if (secondaryActionRef) {
      secondaryActionRef.addEventListener('mouseenter', mouseEnterFunc)
      secondaryActionRef.addEventListener('mouseleave', mouseLeaveFunc)
    }

    const handleBeforeUnload = () => {
      cancelAnimationFrame(animationFrameId.current)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      if (primaryActionRef) {
        primaryActionRef.removeEventListener('mouseenter', mouseEnterFunc)

        primaryActionRef.removeEventListener('mouseleave', mouseLeaveFunc)
      }

      if (secondaryActionRef) {
        secondaryActionRef.removeEventListener('mouseenter', mouseEnterFunc)

        secondaryActionRef.removeEventListener('mouseleave', mouseLeaveFunc)
      }
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
      isClosed = true
      window.removeEventListener('resize', resizeFunc)
      window.removeEventListener('scroll', scrollFunc)
      window.removeEventListener('beforeunload', handleBeforeUnload)
      mediaQuery.removeEventListener('change', handleReduceMotionChange)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="lp-IntroVisuals">
      <div className="lp-IntroVisuals-canvasWrapper" ref={wrapperRef}>
        <canvas className="lp-IntroVisuals-canvas" ref={canvasRef} />
      </div>
      <div className="sr-only">{COPY.webgl.alt}</div>
    </div>
  )
}

try{ IntroHero.displayName ||= 'IntroHero' } catch {}