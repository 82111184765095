import GlifCat from '../../../../assets/webgl-assets/mascot/cat/cat.glb'
import MatcapMascot from '../../../../assets/webgl-assets/matcaps/mascot.jpg'
import MatcapCatEye from '../../../../assets/webgl-assets/matcaps/cat_eye.jpg'
import TextureStar from '../../../../assets/webgl-assets/textures/star.jpg'
import TextureCatNoseSSS from '../../../../assets/webgl-assets/mascot/cat/textures/nose_sss.jpg'
import TextureCatHeadSSS from '../../../../assets/webgl-assets/mascot/cat/textures/head_sss.jpg'
import TextureCatFaceSSS from '../../../../assets/webgl-assets/mascot/cat/textures/face_sss.jpg'
import TextureCatEyeSSS from '../../../../assets/webgl-assets/mascot/cat/textures/eye_sss.jpg'
import TextureCatEyeballSSS from '../../../../assets/webgl-assets/mascot/cat/textures/eyeball_sss.jpg'
import TextureCatCatEyeColor from '../../../../assets/webgl-assets/mascot/cat/textures/eye_color.jpg'

import {Color, Vector2} from 'three'
import type {Gltf, Image, TextureData} from './assets-type'

export const catAssets = {
  gltfs: {
    cat: {
      src: GlifCat,
      scene: null,
    },
  } as {[key: string]: Gltf},
  images: {
    // matcap
    matcap_mascot: {
      src: MatcapMascot,
      texture: null,
      flipY: false,
    },
    matcap_cateye: {
      src: MatcapCatEye,
      texture: null,
      flipY: false,
    },
    star: {
      src: TextureStar,
      texture: null,
      flipY: false,
    },
    // cat
    cat_nose_ao: {
      src: TextureCatNoseSSS,
      texture: null,
      flipY: false,
    },
    cat_head_ao: {
      src: TextureCatHeadSSS,
      texture: null,
      flipY: false,
    },
    cat_face_ao: {
      src: TextureCatFaceSSS,
      texture: null,
      flipY: false,
    },
    cat_eye_ao: {
      src: TextureCatEyeSSS,
      texture: null,
      flipY: false,
    },
    cat_eyeball_ao: {
      src: TextureCatEyeballSSS,
      texture: null,
      flipY: false,
    },

    cat_eye_color: {
      src: TextureCatCatEyeColor,
      texture: null,
      flipY: false,
    },
  } as {[key: string]: Image},
}

export const catTextureData: {[key: string]: TextureData} = {
  nose: {
    ao: 'cat_nose_ao',
    color: null,
    colorVec: new Color(0x000000),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  eye: {
    ao: 'cat_eye_ao',
    color: 'cat_eye_color',
    colorVec: new Color(0x000000),
    matcap: 'matcap_cateye',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
  face: {
    ao: 'cat_face_ao',
    color: null,
    colorVec: new Color(0xff8fd6),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.1, 0.1),
    fogRangeZ: new Vector2(-1.0, -0.5),
  },
  head: {
    ao: 'cat_head_ao',
    color: null,
    colorVec: new Color(0xf763c1),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.1, 0.1),
    fogRangeZ: new Vector2(-1.0, -0.5),
  },

  eyeball: {
    ao: 'cat_eyeball_ao',
    color: null,
    colorVec: new Color(0xffffff),
    matcap: 'matcap_mascot',
    noiseRange: new Vector2(-0.03, 0.03),
    fogRangeZ: new Vector2(-1.0, -0.3),
  },
}
