import {CTABanner, Image, Section} from '@primer/react-brand'

import {Spacer} from './_shared'
import CtaForm from './components/CtaForm/CtaForm'
import {useCallback, useEffect, useRef, useState} from 'react'
import useIntersectionObserver from '../../lib/hooks/useIntersectionObserver'
import {COPY} from './Cta.data'

export default function Cta() {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const mascotsRef = useRef<HTMLDivElement | null>(null)
  const ctaRef = useRef<HTMLDivElement | null>(null)

  // TODO: Replace the first once with the WebGL scene's intersection observer logic once added
  const {isIntersecting} = useIntersectionObserver(mascotsRef, {threshold: 0.4, isOnce: true})
  const {isIntersecting: isCtaInView} = useIntersectionObserver(ctaRef, {threshold: 0.4, isOnce: true})

  // TODO: To be called by WebGL scene once added
  const startCopyAnimation = useCallback(() => {
    setIsVisible(true)
  }, [])

  // TODO: To be removed once WebGL scene is added
  useEffect(() => {
    if (isIntersecting) {
      startCopyAnimation()
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting])

  return (
    <Section id="cta">
      <Spacer size="100px" />

      <div ref={mascotsRef} className={`lp-Cta-mascots ${!isVisible ? 'lp-Cta-mascots--hidden' : ''}`}>
        <div className="sr-only">{COPY.visual.alt}</div>
        <div>
          <Image src={COPY.visual.url.glowStart} alt="" loading="lazy" />
        </div>
        <div>
          <Image src={COPY.visual.url.glowEnd} alt="" loading="lazy" />
        </div>
        <div>
          <Image src={COPY.visual.url.ducky} alt="" loading="lazy" />
        </div>
        <div>
          <Image src={COPY.visual.url.mona} alt="" loading="lazy" />
        </div>
        <div>
          <Image src={COPY.visual.url.copilot} alt="" loading="lazy" />
        </div>
      </div>

      <Spacer size="48px" size1012="80px" />

      <CTABanner
        ref={ctaRef}
        className={`lp-Cta ${isCtaInView ? 'is-visible' : ''}`}
        align="center"
        hasBackground={false}
        hasShadow={false}
      >
        <CTABanner.Heading as="h2">{COPY.heading}</CTABanner.Heading>
        <CTABanner.Description className="lp-Cta-description">{COPY.description}</CTABanner.Description>
        <CtaForm location="bottom_cta_section" />
      </CTABanner>
    </Section>
  )
}

try{ Cta.displayName ||= 'Cta' } catch {}