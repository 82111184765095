import background_vert from './shaders/screen-vert'
import background_frag from './shaders/background-frag'

import bgOutput_vert from './shaders/bgOutput-vert'
import bgOutput_frag from './shaders/copy-frag'

import star_vert from './shaders/star-vert'
import star_frag from './shaders/star-frag'
import Timeline from './utils/timeline'
import utilsShaders from './shaders/utils-glsl'
import type Common from './common'
import type {Texture} from 'three'
import {
  Color,
  Group,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  MathUtils,
  Mesh,
  PlaneGeometry,
  Scene,
  ShaderMaterial,
  Vector2,
  Vector3,
  Vector4,
  WebGLRenderTarget,
} from 'three'

interface CommonUniforms {
  uTime: {value: number}
  uScrollProgress: {value: Vector3}
}

export default class Background {
  fbo_blue: WebGLRenderTarget = new WebGLRenderTarget(256, 256)
  group: Group = new Group()
  common: Common
  uScrollProgress: Vector3 = new Vector3(0, 0, 0)
  commonUniforms: CommonUniforms = {
    uTime: {value: 0},
    uScrollProgress: {
      value: this.uScrollProgress,
    },
  }
  bluePlane: Mesh
  uProgressBackground: Vector4 = new Vector4(0, 0, 0, 1)
  uProgressStar: Vector4 = new Vector4(0, 0, 0, 0)
  scene: Scene = new Scene()
  isRounded: boolean = false
  constructor(common: Common, isRounded: boolean) {
    this.common = common
    this.isRounded = isRounded
  }

  init(starTexture: Texture) {
    const mesh = new Mesh(
      new PlaneGeometry(2, 2),
      new ShaderMaterial({
        vertexShader: background_vert,
        fragmentShader: utilsShaders + background_frag,
        uniforms: {
          uColor1: {value: new Color(0x0e0aa2)},
          uColor2: {value: new Color(0x9a7cff)},
          uResolution: {value: new Vector2(256, 256)},
          ...this.commonUniforms,
          uProgress: {
            value: this.uProgressBackground,
          },
        },
        depthTest: false,
        depthWrite: false,
        transparent: true,
        defines: {
          IS_ROUNDED: this.isRounded ? 1 : 0,
        },
      }),
    )

    this.scene.add(mesh)
    this.createBluePlane()
    this.createStars(starTexture)
  }

  createBluePlane() {
    this.bluePlane = new Mesh(
      new PlaneGeometry(5, 5),
      new ShaderMaterial({
        vertexShader: bgOutput_vert,
        fragmentShader: bgOutput_frag,
        uniforms: {
          uTexture: {
            value: this.fbo_blue.texture,
          },
          ...this.commonUniforms,
        },
        depthTest: false,
        depthWrite: false,
        transparent: true,
      }),
    )

    this.bluePlane.position.y = 0.5
    this.bluePlane.position.z = -2

    this.group.add(this.bluePlane)
  }

  createStars(starTexture: Texture) {
    const num = 100
    const _geometry = new PlaneGeometry(0.06, 0.06)
    const instancedGeometry = new InstancedBufferGeometry()

    if (_geometry.attributes.position) {
      const vertice = _geometry.attributes.position.clone()
      instancedGeometry.setAttribute('position', vertice)
    }

    if (_geometry.attributes.normal) {
      const normal = _geometry.attributes.normal.clone()
      instancedGeometry.setAttribute('normals', normal)
    }

    if (_geometry.attributes.uv) {
      const uv = _geometry.attributes.uv.clone()
      instancedGeometry.setAttribute('uv', uv)
    }

    const indices = _geometry.index ? _geometry.index.clone() : null
    instancedGeometry.setIndex(indices)

    instancedGeometry.instanceCount = num

    const translates = new InstancedBufferAttribute(new Float32Array(num * 3), 3, false, 1)
    const randoms = new InstancedBufferAttribute(new Float32Array(num * 3), 3, false, 1)
    instancedGeometry.setAttribute('atranslate', translates)
    instancedGeometry.setAttribute('arandom', randoms)

    for (let i = 0; i < 100; i++) {
      const angle = MathUtils.lerp(Math.PI * 0.25, Math.PI * 0.75, Math.random())

      translates.setXYZ(i, angle, Math.random(), 0)
      randoms.setXYZ(i, Math.random(), Math.random(), Math.random())
    }

    const material = new ShaderMaterial({
      vertexShader: star_vert,
      fragmentShader: star_frag,
      uniforms: {
        uMask: {
          value: starTexture,
        },
        uRadius: {
          value: 2.6,
        },
        uOffsetY: {
          value: 2,
        },
        uProgress: {
          value: this.uProgressStar,
        },
        uOffset: {
          value: this.isRounded ? new Vector3(0.2, 0, 0) : new Vector3(0, 0, 0),
        },
        uScale: {
          value: this.isRounded ? 0.7 : 1,
        },
        ...this.commonUniforms,
      },
      transparent: true,
      depthTest: false,
      depthWrite: false,
    })

    const mesh = new Mesh(instancedGeometry, material)

    mesh.frustumCulled = false
    this.group.add(mesh)
  }

  show() {
    if (this.common.isReducedMotion) {
      this.uProgressStar.x = 1
      this.uProgressStar.y = 1
      this.uProgressBackground.x = 1
      this.uProgressBackground.y = 1
      this.uProgressBackground.z = 1
    } else {
      const tl = new Timeline()
      tl.to([this.uProgressStar], 1000, {x: 1, easing: 'easeOutCubic'}, 0)
        .to([this.uProgressStar], 5000, {y: 1, easing: 'easeOutExpo'}, 0)
        .to([this.uProgressBackground], 1000, {x: 1, easing: 'easeOutCubic'}, 0)
        .to([this.uProgressBackground], 2500, {y: 1, easing: 'easeOutCubic'}, 0)
        .to([this.uProgressBackground], 4000, {z: 1, easing: 'easeOutCubic'}, 0)
        .start()
    }
  }

  resize() {}

  update() {
    this.commonUniforms.uTime.value += this.common.delta
    this.common.renderer?.setRenderTarget(this.fbo_blue)
    this.common.renderer?.render(this.scene, this.common.camera)
  }
}
