import {TextureLoader} from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import type {Gltf, Image} from './params/assets-type'

export default class Assets {
  gltfs: {[key: string]: Gltf}
  images: {[key: string]: Image}
  isLoaded: boolean = false

  constructor(gltfs: {[key: string]: Gltf}, images: {[key: string]: Image}) {
    this.gltfs = gltfs
    this.images = images
  }

  async load(callback: () => void) {
    const allPromisess = [...this.loadImages(), ...this.loadGltfs()]

    try {
      await Promise.all(allPromisess)

      this.isLoaded = true

      // All assets loaded
      if (callback) callback()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error loading assets', error)
    }
  }

  loadGltfs() {
    const loader = new GLTFLoader()

    const promises = Object.values(this.gltfs).map(
      gltf =>
        new Promise((resolve, reject) => {
          loader.load(
            gltf.src,
            loadedGltf => {
              gltf.scene = loadedGltf.scene
              resolve(loadedGltf.scene)
            },
            undefined,
            error => reject(error),
          )
        }),
    )

    return promises
  }

  loadImages() {
    const loader = new TextureLoader()

    const promises = Object.values(this.images).map(
      image =>
        new Promise((resolve, reject) => {
          loader.load(
            image.src,
            texture => {
              image.texture = texture
              texture.flipY = image.flipY
              resolve(texture)
            },
            undefined,
            error => reject(error),
          )
        }),
    )

    return promises
  }
}
