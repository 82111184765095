export const SUBNAV_LINKS = {
  logo: {
    label: 'Copilot',
    url: '/features/copilot',
  },
  items: [
    {
      label: 'Extensions',
      url: '/features/copilot/extensions',
    },
    {
      label: 'Plans',
      url: '/features/copilot/plans',
    },
  ],
}
